.status-online {
  color: #155724;
  /* background-color: #535f70; */
  font-weight: bold;
}
.real_status-Online {
  background-color: #28a74680; /* Light green for online */
  color: #155724; /* Dark green text */
}
.real_status-Offline {
  background-color: #f8d7da; /* Light red for offline */
  color: #721c24; /* Dark red text */
}
/* Ensure the entire cell adopts the background color */
.center-td {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.status-break {
  color: #ff8282 ;
  font-weight: bold;
}
.activity-status.on-call {
  background-color: #09C948 ;
  color: white;
  padding: 5px 5px;
  border-radius: 4px;
}
.activity-status.logged-out {
  background-color: #ff8282 ;
  color: white;
  padding: 5px ;
  border-radius: 10px;
}
.aqr_block h5 {
  color: #535f70;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.aqr_block h6 {
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 600;
  margin: 4px 0 0;
}
.status-online {
  color: green;
  font-weight: bold;
}
.status-break {
  color: red;
  font-weight: bold;
}
.agentprime {
  display: inline-block;
  margin-bottom: -1px;
}
.real_web, .real_phone {
  font-weight: bold;
  color: white !important;
  background: #09c948 !important;
  border: 0.1px solid #09a93e !important;
}
.real_break {
  font-weight: bold;
  color: white !important;
  background: #09c948 !important;
  border: 0.1px solid #09a93e !important;
}
.real_break.overtime{
  background: red !important;
  border: 0.1px solid red !important;
}
.real_usertools div {
  float: left;
  font-size: 11px;
  color: #1b1b1b;
  background: #f3eded;
  border-radius: 2px;
  border: 1px solid #e2e2e6;
  padding: 2px 4px;
  margin-right: 2px;
}
.center-td span {
  border-radius: 10px;
  border: 0.1px solid white;
  width: 110px;
  padding: 2px 0 1px;
  margin: auto;
  font-size: 10px;
  font-weight: 600;
  color: white;
  border-radius: 20px !important;
  padding: 4px 12px !important;
  width: auto !important;
}
.no-wrap {
  white-space: nowrap;
}
.real_status-Offline {
  background: #ff8282;
}
.real_status-OnCall {
  background: #39ca65;
}
.real_status-Paused,
.real_phone_pause_break,
.real_status-Break {
  background: #ea842c;
}
.activity-status.Break {
  background-color: #ea842c; /* Green background for idle */
  color: rgb(255, 255, 255);
  padding: 2px 15px; /* Increased horizontal padding for wider appearance */
  border-radius: 10px; /* Same rounded corners as logged out */
  display: inline-block; /* Ensures the padding affects width */
  min-width: 90px; /* Set a minimum width if needed */
  min-height: 20px;
  text-align: center; /* Center the text */
}
/* Styles for idle activity */
.activity-status.idle {
  background-color: #28a74680; /* Green background for idle */
  color: rgb(255, 255, 255);
  padding: 2px 15px; /* Increased horizontal padding for wider appearance */
  border-radius: 10px; /* Same rounded corners as logged out */
  display: inline-block; /* Ensures the padding affects width */
  min-width: 90px; /* Set a minimum width if needed */
  min-height: 20px;
  text-align: center; /* Center the text */
}

.agent-info-popup {
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  transform: translateX(-50%);
}
.agent-popup-header h1 {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}
.agent-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: #cfe4ff;
}
.agent-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: #cfe4ff;
}
.agent-popup-content {
  padding: 14px;
  background-color: #fff;
  color: #0d6efd;
}
span.popup-info-icon {
  background-color: #ddebff;
  color: #fff;
  padding: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.info-icon-list {
  display: inline-flex;
  align-items: center;
}
.list-info {
  border-left: 1px solid #ccc;
  padding-left: 10px;
  margin-left: 10px;
}
.agent-popup-footer .btn {
  border-radius: 16px !important;
  padding: 4px 20px !important;
}

.agent-popup-footer .button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.agent-popup-footer .button-row {
  display: flex;
  gap: 10px;
}



