.script-detail-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

.script-name {
    font-style: italic;
    font-size: 24px;
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
}

.script-box {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.05);
    min-height: 100px;
}

.description-box {
    background-color: #f8f9fa;
}

.text-box {
    background-color: #e9ecef;
}

.script-box h3 {
    color: #555;
    margin-bottom: 15px;
}

.script-box p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
}
