.highlighted {
  background-color: #e0e0e0; 
  border-left: 4px solid #007bff;
  color: #007bff;
}
.time_input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.time_input_wrapper .form-control {
  flex: 1;  /* ensures the input takes all available space */
  padding-right: 30px;  /* makes room for the icon inside the input */
}

.time_input_wrapper .input_icon {
  position: absolute;
  right: 10px;  /* adjust this value based on your styling needs */
  pointer-events: none;  /* makes the icon non-clickable so the input field behind it is clickable */
}

.editor-container {
  background-color: #94939343;
  max-width: 800px; /* Adjust this value to your preferred max width */
  margin: 0 auto;
  height: 30vh;   /* Center the editor horizontally */
}
.content-body-activity {
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  position: relative;
  display: flex;
}
.followup_recent {
  display: flex;
  flex: 0 0 320px;
  flex-direction: column;
  height: 100%;
  max-width: 320px;
}
.content-body-activity .followup_recent {
  overflow-y: auto;
}
.set_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border-bottom: 1px solid #e2e2e6;
}
.set_header h4 {
  font-size: 22px;
}
.set_body {
  flex: 1;
  overflow-y: auto;
}
.followup_recent .recents_table {
  height: 400px;
}
.rtm_blocks {
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 8px;
  grid-template-columns: 40px auto 40px;
  padding: 10px 16px;
  width: 100%;
}
.rtmactive {
  color: #0d84d9;
}
.rtm_blocks .block_icon {
  align-items: center;
  background: #dfe3ec;
  border: 1px solid #e2e2e6;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}
.rtm_blocks .block_call {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 100%;
}
.rtm_blocks .call_icon {
  cursor: pointer;
  display: grid;
  place-items: center;
  height: 32px;
  width: 32px;
  background: #0d84d9;
  border-radius: 50%;
  border: 1px solid #0d84d9;
  transition: 0.2s;
}
.rtm_blocks .call_icon i {
  color: white;
  font-size: 10px;
  margin: 0;
}
.rtm_blocks span {
  font-size: 14px;
  display: block;
}
.user_activity_pane {
  border-left: 1px solid #e2e2e6;
  border-right: 1px solid #e2e2e6;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}
.activity_info {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 12px 20px 8px;
  transition: 0.3s;
  width: 100%;
}
.activity_info .userimg {
  align-items: center;
  background: #dfe3ec;
  border: 1px solid #e2e2e6;
  border-radius: 50%;
  display: flex;
  flex: 0 0 48px;
  height: 48px;
  justify-content: center;
  max-width: 48px;
}
.userimg img {
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.activity_info .activity_info_details {
  flex: 1;
}
.activity_info .activity_info_details h4,
.activity_info .activity_info_details #cur_caller_email {
  margin-top: 0px;
  margin-bottom: 5px;
}
.btn-circle {
  align-items: center;
  background: transparent;
  border: 1px solid #0d84d9;
  border-radius: 50% !important;
  color: #0d84d9;
  display: flex;
  justify-content: center;
  height: 32px;
  width: 32px;
  transition: all 0.36s;
}
.btn-circle:hover {
  background: #e8f1ff;
  text-decoration: none;
}
.btn_grp {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
}

li.nav-item {
  margin-right: 24px;
}
.nav-item button {
  background: transparent;
  border: 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  padding: 8px 0;
  transition: all 0.36s;
  color: #000;
  border-width: 0px 0px 4px 0px !important;
}
.nav-link.active {
  border-bottom-color: #0d84d9 !important;
  color: #0d84d9 !important;
  border-width: 0px 0px 4px 0px !important;
}
.activity_timeline {
  position: relative;
  padding: 12px 20px 0;
  color: #000 !important;
  font-size: 14px;
}
.tab-pane::-webkit-scrollbar-thumb {
  background: #b1cce4;
  border-radius: 10px;
  transition: 1s;
  width: 5px;
}
.tab-pane-script::-webkit-scrollbar-thumb {
  background: #b1cce4;
  border-radius: 10px;
  transition: 1s;
  width: 5px;
}
.tab-pane-script {
  padding: 12px 20px;
  overflow-y: auto;
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination li a {
  display: block;
  padding: 8px 12px;
  text-decoration: none;
  color: #007bff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.pagination li a:hover {
  background-color: #e9ecef;
  color: #0056b3;
}

.pagination li.active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination li.disabled a {
  color: #6c757d;
  pointer-events: none;
  border-color: #dee2e6;
}
.tab-pane {
  padding: 12px 20px;
  overflow-y: auto;
  /* height: 300px; */
}
.tab-pane-calls {
  padding: 12px 0px;
}
.tab-content .active {
  background-color: transparent !important;
}
.time_block h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px;
}
.timeline_none {
  background: #dfe3ec;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
.time_block {
  width: 100%;
  margin-bottom: 20px;
}
.time_block:last-child {
  margin-bottom: 10px;
}
.time_block_sub {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
  transition: 0.3s;
}
.time_block_icon {
  align-items: center;
  border: 1px solid #e2e2e6;
  border-radius: 50%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
}
.time_block .time_excerpt {
  flex: 1;
  line-height: 1.45;
}
.time_block_name {
  align-items: center;
  display: flex;
  gap: 16px;
}
.time_block .time_action {
  align-self: center;
}
.time_block_icon img {
  height: 15px;
}
.time_block_hour span {
  font-size: 14px;
  font-weight: 600;
  margin-right: 4px;
}
.floating_container {
  display: flex;
  flex: 0 0 48px;
  max-width: 48px;
  transition: all 0.1s ease-in-out;
}
.btn-float--toggler {
  align-items: center;
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 50%;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  position: absolute;
  left: -16px;
  top: 64px;
  transition: all 0.3s;
  width: 32px;
}
.floating_container.expanded .btn-float--toggler i {
  rotate: 180deg;
}
.floating_buttons {
  position: relative;
}
.floating_container.expanded {
  display: flex;
  flex-direction: column;
  flex: 0 0 426px;
  max-width: 426px;
}
.btn_fab {
  align-items: center;
  background: transparent !important;
  border-radius: 4px;
  color: #1b1b1b !important;
  display: flex !important;
  font-size: 18px !important;
  height: 32px;
  justify-content: center;
  outline: none;
  transition: all 0.25s;
  width: 32px;
}
.btn_fab.active {
  background: #cfe4ff !important;
  border-color: #9ccaff !important;
  color: #0d84d9 !important;
}
.floating-container ul#pills-tab {
  border-bottom: 1px solid #ccc;
  gap: 12px;
}
.floating_container.expanded ul#pills-tab {
  padding: 22px !important;
  gap: 14px;
}
.floating_container ul li {
  margin-top: 14px;
}
.floating_header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 32px;
  padding: 8px 0;
}
.floating_header_script {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}
.tab-pane-script:not(.active), .tab-pane-script,
.tab-pane-script:not(.active) .floating_header_script,
.tab-pane-script:not(.active) .script-pagination-container,
.tab-pane-script:not(.active) .script-table-box {
  display: none;
}

.tab-pane-script.active,
.tab-pane-script {
  display: block;
}

.floating_header_script h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #000;
}
.script-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.script-table-box {
  padding: 12px 20px;
}
.floating_header h3 {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: #000;
}
.filter_search button {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #535f70;
  margin-left: 4px;
}
.addnote_title .addnote_names {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2e2e6;
  border-radius: 4px;
  border: 1px solid #c6c6ca;
  cursor: pointer;
  transition: 0.2s;
  padding: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: #000;
}
.calendar-wrapper {
  width: 360px;
  margin: 0 auto;
  padding: 12px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}
.calendar-wrapper table {
  clear: both;
  width: 100%;
  border-radius: 3px;
  border-collapse: collapse;
  color: #444;
}
.calendar-wrapper td {
  height: 48px;
  text-align: center;
  vertical-align: middle;
  padding: 12px;
  border: 1px slid rgb(220, 220, 255);
  width: 100% / 7;
}
.calendar-wrapper td.not-current {
  color: #c0c0c0;
}
.calendar-wrapper td.today {
  border: 1px solid #ccc;
}
.calendar-wrapper thead td {
  border: none;
  color: rgb(40, 40, 59);
  text-transform: uppercase;
}
.calendar-wrapper #btnPrev {
  left: 34px;
}
.calendar-wrapper #btnPrev:before {
  content: "\f104";
  font-family: FontAwesome;
  padding-right: 4px;
}
.calendar-wrapper #btnNext {
  right: 32px;
}
.calendar-wrapper #btnNext:after {
  content: "\f105";
  font-family: FontAwesome;
  padding-left: 4px;
}
.calendar-wrapper #btnPrev,
.calendar-wrapper #btnNext {
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #c0c0c0;
  cursor: pointer;
  position: absolute;
  top: 24px;
  transition: all 0.3s ease;
}
.floating_container .tab-content {
  display: none;
}
.floating_container.expanded .tab-content {
  display: block;
}

.button {
  padding: 0.5rem;
  cursor: pointer;
}
.button svg {
  width: 16px;
}
#editor-box .select,
#editor-box input {
  margin: 0.5rem;
  width: 20px;
}
.writer-js {
  background-color: rgb(249, 249, 249);
  width: 98.5%;
  height: 200px;
  margin: 0.5rem;
  text-align: center;
  border-radius: 10px;
  border: none;
}
div#top-bar {
  flex-wrap: wrap;
}
#carbonads {
  display: block;
  overflow: hidden;
  max-width: 728px;
  position: relative;
  font-size: 22px;
  box-sizing: content-box;
}
#carbonads > span {
  display: block;
}
#carbonads a {
  color: #111;
  text-decoration: none;
}
#carbonads a:hover {
  color: #000;
}
.carbon-wrap {
  display: flex;
  align-items: center;
}
.carbon-img {
  display: block;
  margin: 0;
  line-height: 1;
}
.carbon-img img {
  display: block;
  height: 90px;
  width: auto;
}
.carbon-text {
  display: block;
  padding: 0 1em;
  line-height: 1.35;
  text-align: left;
}
.carbon-poweredby {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 600;
  font-size: 8px;
  border-top-left-radius: 4px;
  line-height: 1;
  color: #ccc !important;
}

.script-list-container {
  overflow-y: auto;
  height: 200px;
}

.script-list-box {
  background-color: #E2E2E6;
  border-radius: 4px;
  border: 1px solid #C6C6CA;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.note-list-box {
  display: flex;
  justify-content: 'space-between';
  align-items: 'center';
  position: 'relative';
}

.note-list-box:hover .note-icons {
  visibility: visible;
  opacity: 1;
}

.p-datepicker table td {
  padding: 0.1rem !important;
  }

.note-icons {
  transition: visibility 0.2s, opacity 0.2s ease;
  opacity: 0;
  visibility: 'hidden'
}

@media only screen and (min-width: 320px) and (max-width: 759px) {
  .carbon-text {
    font-size: 14px;
  }
}

.container {
  margin: 0 auto;
  max-width: 640px !important;
}

.btn {
  padding: 1.25rem;
  border: 0;
  border-radius: 3px;
  /* background-color: #4f46e5; */
  color: #fff;
  cursor: pointer;
}
.floating_container ul#pills-tab {
  padding: 20px 8px !important;
}
.activity_titles {
  padding: 0px 20px !important;
}

@media (max-width: 768px) {
  .content-body-activity {
    flex-direction: column;
  }
  .followup_recent {
    max-width: 100%;
  }
  .floating_container.expanded {
    max-width: 100%;
  }
}
