.filter_group {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px 8px;
}
.show_advfilter input[type="checkbox"] {
  appearance: none;
  background: transparent;
  border: 2px solid #909194 !important;
  border-radius: 4px;
  margin: 0 !important;
  outline: none !important;
}
.show_advfilter input[type="checkbox"]:checked {
  background-color: #0d84d9;
  border-color: #0d84d9 !important;
}
.show_advfilter input[type="checkbox"] {
  cursor: pointer;
  height: 20px;
}
.show_advfilter label {
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 4px;
}
.show_advfilter .filter_item select:focus {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
p {
  font-size: 14px;
}
