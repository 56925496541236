* {
  scrollbar-color: #b1cce4 transparent !important;
  scrollbar-width: thin !important;
}
a {
  text-decoration: none !important;
}
.header {
  align-items: center;
  background: #fcfcfc;
  border-bottom: 1px solid #e2e2e6;
  color: #1b1b1b;
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.logo-box img {
  max-width: 100px;
}
.user-account {
  align-items: center;
  cursor: pointer;
  display: flex;
  grid-gap: 8px;
  min-width: 148px;
}
.user-account .user_media {
  border: 1px solid #eee;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  place-items: center;
  position: relative;
}
.user-account .user_media img {
  border-radius: 50%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  font-size: 0.75rem;
  overflow: hidden;
  width: 100%;
}
.user_excerpt .user_name {
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 600;
}
.user_excerpt .user_info span {
  color: #535f70;
  font-size: 12px;
  font-weight: 600;
}
.header-group {
  align-items: center;
  display: flex;
  gap: 16px;
  height: 100%;
}
.user-account .user_media .header_status {
  align-items: center;
  background: #fcfcfc;
  bottom: 0;
  border-radius: 50%;
  display: flex;
  height: 16px;
  justify-content: center;
  line-height: 1;
  position: absolute;
  right: 0;
  width: 16px;
  z-index: 1;
}
.red {
  color: rgb(239 101 101);
}

.btn-transparent {
  align-items: center;
  background: transparent;
  box-shadow: none;
  border: none;
  color: #0d84d9 !important;
  display: flex;
  gap: 4px;
}
.dropdown_account {
  width: 320px;
}
.dropdown_group {
  border-bottom: 1px solid #eeeeee;
  padding: 12px 24px;
}
.profile {
  display: flex;
  padding: 0;
  position: relative;
  gap: 16px;
}
.profile_md .profile_media {
  flex: 0 0 56px;
  height: 56px;
  max-width: 60px;
}
.profile h4,
.profile h5,
.profile h6,
.profile_excerpt span {
  font-size: 14px;
  margin: 0;
}
.profile_media img {
  font-size: 14px;
  height: 100%;
  justify-content: center;
  line-height: 40px;
  object-fit: cover;
  text-align: center;
  width: 100%;
  border-radius: 50%;
}
.dropdown_link {
  align-items: first baseline;
  color: #1b1b1b;
  display: flex;
  gap: 8px;
  padding: 6px 24px;
  text-decoration: none;
  font-size: 14px;
}
.dropdown_link i {
  flex: 0 0 20px;
  max-width: 20px;
  text-align: center;
}
.list_sm {
  font-size: 12px;
  line-height: 1.25;
}
.list_info {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list_item {
  display: flex;
  gap: 6px;
  margin-bottom: 6px;
}
.apurple {
  color: #407ebd;
}
.dropdown_group__title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.dropdown-app-settings .dropdown-item {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 0px 16px;
}
.title {
  font-weight: 600;
  line-height: 1.2;
  color: #1b1b1b;
  margin: 0.5rem 0 0.5rem;
  font-size: 14px;
}
.form-check-input:checked {
  background-color: #75c139;
  border-color: #75c139;
}
.form-check-input {
  border-color: #75c139;
}
.form-check-input:focus {
  border-color: #75c139;
  outline: 0;
  box-shadow: none;
}
.insights_item {
  align-items: center;
  display: flex;
  gap: 4px;
}

.insights_bar::-webkit-scrollbar {
  display: none;
}
.insights_item:after {
  color: #535f70;
  content: "|";
  display: inline-block;
  padding: 0 12px;
}
.insights_excerpt p {
  font-size: 11px;
  line-height: 1.35;
  margin: 0;
  padding: 0;
}
.insights_item:last-child:after {
  display: none;
}
@media (max-width: 768px) {
  .insights_bar {
    margin-left: 80px;
  }
}
