.block-widget {
  color: #000;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e2e2e6;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  align-items: flex-start;
  padding: 10px;
  background-color: #fff;
}
form {
  width: 100%;
}
.block-widget h6 {
  color: #1b1b1b;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  margin: 0;
}
.block-widget h2 {
  color: #1b1b1b;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
  margin: 0;
}
.block-widget p {
  color: #1b1b1b;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  margin: 0;
  text-align: left;
}
.block-widget .form_group {
  margin-bottom: 16px;
  max-width: 100%;
  text-align: start;
  width: 100%;
}
.phone-block {
  padding: 14px;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 0px 11px 0px #ccc;
  width: 100%;
}
button#connect {
  background-color: #04aa6d;
}
.connection button:disabled {
  opacity: 0.5;
}
.connection button {
  border: none;
  color: white;
  padding: 10px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
button#disconnect {
  background-color: #f44336;
  margin-left: 5px;
}

.connection button {
  border: none;
  color: white;
  padding: 10px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
#incoming-number {
  height: 20px;
  color: #81c784;
}
#output > input {
  font-size: 20px !important;
  height: 40px;
  font-weight: bold;
  color: #1976d2;
  width: 100%;
}
.number-flex {
  margin: 0 auto;
  display: flex;
  clear: both;
  text-align: center;
}
.box {
  padding: 2px 30px;
  font-size: 2rem;
  cursor: pointer;
}
button.not-visible {
  font-size: inherit;
  background: transparent;
  border: none;
}
.sub {
  font-size: 0.8rem;
  color: grey;
}
.digit:active,
.dig:active,
.box.active {
  background-color: #e6e6e6;
}
.box.mute,
.box.hold {
  font-size: 1.5rem;
  position: relative;
}
.phone-block input[type="checkbox"].not-visible {
  opacity: 0;
  position: absolute;
  width: 25px;
  height: 25px;
}
.botrow {
  margin: 0 auto;
  clear: both;
  text-align: center;
  display: flex;
}
.call:is([disabled]) {
  visibility: hidden;
}
.call.reject {
  background-color: #dc3545;
}
.call {
  display: inline-block;
  padding: 4px 30px;
  margin: 10px;
  color: white;
  border-radius: 4px;
}
.call button:not([disabled]) {
  cursor: pointer;
  pointer-events: inherit;
}
button.not-visible {
  font-size: inherit;
  background: transparent;
  border: none;
}
.call.answer {
  background-color: #66bb6a;
}
i.icon-arrow-left.backspace {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
@media (max-width: 767px) {
  .connection button {
    padding: 6px 20px;
    font-size: 14px;
  }
}
