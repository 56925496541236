body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
}
ul,
ol,
dl {
  padding: 0;
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
  font-size: 14px;
}
.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.eot");
  src: url("../fonts/Helvetica.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Helvetica.woff2") format("woff2"),
    url("../fonts/Helvetica.woff") format("woff"),
    url("../fonts/Helvetica.ttf") format("truetype"),
    url("../fonts/Helvetica.svg#Helvetica") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Login-page-css */
a {
  color: #337ab7;
  text-decoration: none;
}
/* .main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */
.login-box {
  border: 1px solid rgba(128, 128, 128, 0.361);
  border-radius: 5px;
  padding: 30px;
  background-color: #bab7b70f;
  max-width: 340px;
  text-align: center;
  width: 100%;
  font-size: 13px;
  margin: 5px 80px;
}
.footer-box {
  max-height: 100vh;
  max-width: 360px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 5px 70px;
}
.login-header img {
  max-width: 100px;
}
.login-box h4 {
  margin: 0 0;
  font-size: 18px;
  color: #333;
}
.login-box p {
  color: #535f70;
  /* margin: 0 0 16px; */
  color: #333;
}
.login-form {
  background: #fcfcfc;
  border: 2px solid #e2e2e6;
  border-radius: 4px;
  /* margin: 12px 0; */
  /* padding: 24px; */
  width: 100%;
}
.login-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.forusername,
.forpassword {
  align-items: center;
  background: none;
  border: 1px solid #73777f;
  border-radius: 4px;
  color: #4283fb;
  display: flex;
  justify-content: flex-end;
  outline: none;
  padding: 0 8px 0 0;
  width: 100%;
}
.forusername input,
.forpassword input {
  color: #636060;
  background: none;
  font-size: 14px;
  outline: none;
  width: 100%;
  border: none;
  padding: 5px 12px;
}
.login-box .login-input .forpassword i {
  cursor: pointer;
  color: #ff5959;
}
.btn-primary {
  font-size: 14px;
  color: #fff;
  background-color: #337ab7;
}
.login-footer {
  color: #535f70;
}
.login-footer h6 {
  font-size: 12px;
}
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1000;
}
.toast.success {
  background-color: green;
}
.toast.error {
  background-color: red;
}
.toast.show {
  opacity: 1;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #4285f4;
}

.form-control:focus {
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.25);
}
.logo-header {
  max-height: 50vh;
  max-width: 360px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin: 6px 70px; */
}
.logo-header img {
  width: 25%;
}
.forgot-pass {
  text-decoration: none;
  color: #337ab7;
}
input {
  background-color: transparent;
}
