.go-back-btn {
  background-color: white;
  color: black;
  border: 1px solid rgba(66, 64, 64, 0.696);
  border-radius: 5%;
  width: 95px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
.go-back-btn:hover {
  background-color: rgba(128, 128, 128, 0.324);
  /* color: white; */
}
/* Breadcrumb styling */
.breadcrumb {
  font-size: 16px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 10px;
}

.breadcrumb span {
  font-weight: 600;
}

/* Article content styling */
.knowledge-article-details {
  padding: 20px;
}
/* .colhov {
  background-color: #1d5cc0 !important;
} 
table>:not(caption)>*:hover{
  background-color: #E8F1FF  !important;
}  */

.knowledge-article-details h1 {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
}

.article-content {
  font-size: 18px;
  margin-top: 20px;
}

.page_header {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.table .action-column {
  text-align: right; /* Align the action column to the right */
}
.action-buttons {
  margin-right: 10px; /* Adjust the value as needed */
}
.page_control {
  align-items: center;
  display: flex;
  gap: 4px;
}
.page_header h1 {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}
.page_control .offcanvas-end {
  top: 56px !important;
}

.btn {
  align-items: center;
  border-radius: 4px !important;
  display: inline-flex;
  font-size: 14px !important;
  gap: 4px;
  justify-content: center;
  line-height: 20px !important;
  padding: 4px 12px !important;
  background-color: #0d84d9 !important;
  color: #fff;
}
.btn:hover {
  background-color: #2a6497 !important;
}
.btn-icons {
  align-items: center;
  border-radius: 4px !important;
  display: inline-flex;
  font-size: 14px !important;
  gap: 6px;
  justify-content: center;
  line-height: 20px !important;
  padding: 4px 12px !important;
  border: 1px solid#0D84D9 !important;
  background-color: white;
  color: #0d84d9 !important;
}
input,
select,
.form-control {
  background: transparent;
  border: 1px solid #909194;
  border-radius: 4px;
  color: #1b1b1b;
  font-size: 14px !important;
  height: 32px;
  line-height: 20px;
  padding: 5px 12px;
  width: 100%;
}
.btn i {
  font-size: 14px;
  line-height: inherit;
}
.popup_header {
  align-items: center;
  border-bottom: 1px solid #e2e2e6;
  display: flex;
  flex: 0 0 60px;
  font-weight: 600;
  gap: 16px;
  justify-content: space-between;
  max-height: 60px;
  position: relative;
}
.form-group label {
  font-weight: 600;
}
label {
  color: #1b1b1b;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.form-group {
  margin-bottom: 16px;
}
input,
select,
.form-control {
  background: transparent;
  border: 1px solid #909194;
  border-radius: 4px;
  color: #1b1b1b;
  font-size: 14px;
  height: 32px;
  line-height: 20px;
  padding: 5px 12px;
  width: 100%;
}

.offcanvas-footer {
  align-items: center;
  border-top: 1px solid #e2e2e6;
  display: flex;
  flex: 0 0 60px;
  gap: 4px;
  max-height: 60px;

  padding: 10px 0px;
  z-index: 9;
  background-color: #fff;
}
.search-box {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-left: 15px;
}
.form-check {
  align-items: center;
  display: flex !important;
  gap: 6px;
}
.filter_search {
  align-items: center;
  border: 1px solid #73777f;
  border-radius: 4px;
  display: flex;
  padding: 0 4px 0 12px;
}
.filter_search input {
  border: 0;
  border-radius: 0;
  height: 30px;
  min-width: 160px;
  padding-left: 0;
  padding-right: 0;
}
table tr th,
table tr td {
  font-size: 14px;
}
/* table tr:hover{
 background-color: red;
} */

.scroll_y {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.contact-table,
.knowledge-topics {
  margin: 20px 0px;
  overflow: auto;
  padding-bottom: 4%;
  max-height: 54vh;
}
.table-header--sticky thead tr th {
  position: sticky;
  top: -1px;
  z-index: 9;
}
.table > thead > tr > th {
  padding: 8px 12px;
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  vertical-align: middle;
  position: sticky;
  top: 0;
}
.table > tbody > tr > td {
  padding: 8px 12px;
  font-weight: 400;
  font-size: inherit;
  vertical-align: middle;
}

.offcanvas.addnewKB {
  top: 54px !important;
}
#offcanvasTop {
  height: 100vh !important;
}

.go-back-button {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 50px;
  padding: 6px 12px;
  color: #495057;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.go-back-button:hover {
  background-color: #e9ecef;
  color: #212529;
}
