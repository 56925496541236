.lyt {
  display: flex;
  gap: 12px;
  height: 100%;
  width: 100%;
}
.lyt_y {
  flex-direction: column;
}
.lyt_hd {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
.filter_group {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px 8px;
}
.widget {
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.widget_header {
  align-items: center;
  border-bottom: 1px solid #e2e2e6;
  display: flex;
  flex: 0 0 48px;
  justify-content: space-between;
  max-height: 48px;
  padding: 8px 16px;
}
.widget_card .widget_header {
  flex: 1;
  border-bottom: 0;
}
.widget_header h3,
.widget_header h4,
.widget_header h5,
.widget_header h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.widget_body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  overflow: hidden;
  padding: 8px 16px;
}
.widget_card .widget_body {
  padding-top: 4px;
}
.widget_value h2,
.widget_value h3,
.widget_value h4 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
select,
input,
textarea:focus-visible {
  outline: transparent;
}
.ticket-table
{
  margin: 20px 0px;
  overflow: auto;
  height: 480px;
}