.custom-checkbox .custom-check-input {
  display: none;
}

.custom-checkbox .custom-check-label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.custom-checkbox .custom-check-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #515356;
  border-radius: 3px;
  transform: translateY(-50%);
  background: white;
}

.custom-checkbox .custom-check-input:checked + .custom-check-label:before {
  /* background-color: #0d6efd; */
  /* border-color: #0d6efd; */
}

.custom-checkbox .custom-check-input:checked + .custom-check-label:after {
  content: '\2714';
  position: absolute;
  left: 1px;
  top: 32%;
  transform: translateY(-50%);
  color: white;
  font-size: 14px;
}
.content_flex {
  display: flex;
  margin-top: 2%;
  height: 100%;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  gap: 8px;
  background: #fcfcfc;
}
.accordion {
  width: 100%;
}
.nav_setings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px;
  flex: 0 0 216px;
  border-right: 1px solid #e2e2e6;
}
.content_flex .lyt_box {
  border: none;
  padding: 12px 16px 12px 0px !important;
  margin-top: 0px;
}

.lyt_box {
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  padding: 12px 16px;
}
.lyt_y {
  flex-direction: column;
}
.lyt {
  display: flex;
  gap: 12px;
  height: 100%;
  width: 100%;
  /* width: calc(100% - 300px); */
  margin-top: 60px;
}

.accordion-body .nav_item {
  display: block;
  color: #000;
  padding: 4px 16px !important;
  font-size: 14px;
  color: #007acd;
}
.accordion-item {
  border: none !important;
  margin-bottom: 6px;
}
.accordion-header {
  margin-bottom: 0;
  padding: 4px 16px !important;
}
.accordion-header button {
  color: #003259;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  padding: 0;
  background: none;
}
.accordion-button:not(.collapsed),
.accordion-button:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-body {
  padding: 6px 8px 6px 0px !important;
}
.accordion-item:first-of-type {
  border: none !important;
  background: none;
}
.page_count {
  color: #535f70;
}
.lyt_hd {
  align-items: center;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.lyt_bd {
  flex: 1;
}
.profile {
  display: flex;
  padding: 0;
  position: relative;
  gap: 16px;
}
.profile_media {
  font-size: 20px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #001d36;
  background: #9ccaff;
  border-radius: 50%;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile_media img {
  font-size: 14px;
  height: 100%;
  justify-content: center;
  line-height: 40px;
  object-fit: cover;
  text-align: center;
  width: 100%;
}
.profile_excerpt {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.accordion-button::after {
  background-size: 16px !important;
  margin-top: -4px;
}
.profile h4,
.profile h5,
.profile h6 {
  font-size: 14px;
  margin: 0;
}
.profile p {
  margin: 0;
}
.table-box {
  margin: 20px 0px;
  overflow: auto;
  padding-bottom: 10%;
  max-height: 46vh;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  background-color: #fff !important;
}
.table-box .dropdown-item {
  color: #1b1b1b;
  gap: 1rem;
  display: flex;
  align-items: center;
  padding: 6px 16px !important;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  white-space: nowrap;
}
.table-box .dropdown-item i {
  color: #535f70;
  height: 14px;
  width: 14px;
  text-align: center;
}
.form_group {
  margin-bottom: 16px;
  max-width: 100%;
}
select:focus-visible,
input:focus-visible,
textarea:focus-visible {
  outline: none !important;
}
.form-switch .form-check-input:checked {
  background-color: #75c139;
  border-color: #75c139;
}
.form-switch .form-check-input {
  width: 40px !important;
  height: 20px !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}
.table > tbody > tr {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.dropdown-item:hover,
.nav_item:hover {
  background-color: #dfe3ec !important;
  color: #0d84d9 !important;
}
@media (max-width: 767px) {
  .content_flex {
    flex-direction: column;
  }
  .lyt {
    width: 100%;
  }
}
#assigned_agents_list,
.assigned_agents_list {
  font-size: 11px;
  font-weight: 600;
  color: #42474e;
  background: #e8f1ff !important;
  border-radius: 8px !important;
  border: 1px solid #73777f;
  list-style: none;
  transition: 0.3s;
  float: left;
  padding: 3px 6px;
  margin: 4px 6px 0 0;
}

.checkbox_holder input[type="checkbox"],
.modal input[type="checkbox"]:not(.slider_switch) {
  background: transparent;
  border: 2px solid #909194 !important;
  border-radius: 4px;
  margin: 5px !important;
  outline: none !important;
  padding: 0 !important;
}
span {
  font-size: 14px;
}
.checkbox_holder input[type="checkbox"].check_input {
  border-color: #0d84d9 !important;
}
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.checkbox_holder input[type="checkbox"]:checked {
  background-color: #0d84d9;
  /* background-image: url(../Images/Icons/check.svg); */
  border-color: #0d84d9 !important;
}
.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

input[type="radio"] {
  cursor: pointer;
  height: 25px;
  width: 20px;
}
.form_group__inline {
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.form_group__inline .form-set__title {
  flex: 0 0 260px;
}
.form_group__inline .form-set__title h6 {
  font-size: 14px;
  margin-bottom: 2px;
}
.form-set__title p {
  color: #46474a;
  font-size: 14px;
}
.form_check__tab {
  align-items: center;
  display: flex;
}
.form_check__tab .form_check {
  margin-left: -1px;
  padding: 0;
}
.form_check {
  align-items: center;
  display: flex;
  gap: 6px;
  max-width: 100%;
  padding: 6px 0;
}
.form_check__tab .form_check input {
  display: none;
  height: 0;
  width: 0;
  visibility: hidden;
}
.form_check__tab .form_check:first-child label {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  margin-left: 0;
}
.form_check__tab .form_check label {
  background: #f1f0f4;
  border: 1px solid #73777f;
  display: inline-flex;
  position: relative;
  height: 32px;
  margin: 0;
  padding: 4px 16px;
  cursor: pointer;
  transition: all 0.05s ease-in-out;
}
.form_check__tab .form_check input:checked + label {
  background: #cfe4ff;
  border: 1px solid #0d84d9;
  color: #0d84d9;
  position: relative;
  z-index: 1;
}
.form_check__tab .form_check:last-child label {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.content_empty,
.alert_empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 32px;
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}
.alert_empty h4 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  color: #000;
}
.alert_empty p {
  margin: 4px 0 0;
  color: #000;
}
.view_max__width {
  max-width: 680px;
}
.content_main {
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  padding: 16px;
}
.form_group__inline .form_label__group {
  flex: 0 0 300px;
  max-width: 300px;
}
.form_description {
  font-size: 14px;
}
.form_label__group label {
  margin-bottom: 0;
}
.ivr_setting form .form-item {
  display: flex;
  gap: 1.5rem;
  flex-basis: 100%;
}
.ivr_setting form .form-item .form-label {
  font-weight: 600;
  flex: 0 0 355px;
}
.ivr_setting form .form-item .form-label p {
  margin: 0;
  color: #73777f;
  font-size: 14px;
}
.dat_ret_switch {
  position: relative;
  display: inline-block;
  width: 50px;
}

.slider.round {
  border-radius: 50px;
}
.dat_ret_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff7979;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.dat_ret_on {
  display: none;
  float: left;
  margin: 3px 0 0 5px;
}
.form_group__title {
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 14px;
}
.dat_ret_on,
.dat_ret_off {
  color: white;
  font-size: 10px;
}
.dat_ret_off {
  float: right;
  margin: 3px 5px 0 0;
}
.dat_ret_switch input {
  opacity: 0;
}
.slider.round:before {
  border-radius: 50%;
}
.dat_ret_switch .slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3.5px;
  background-color: white;
  transition: 0.4s;
}
.dat_ret_switch input[type="checkbox"] {
  appearance: none;
  background: transparent;
  border: 2px solid #909194 !important;
  border-radius: 4px;
  margin: 0 !important;
  outline: none !important;
}
input[type="checkbox"]:checked {
  background-color: #0d84d9;

  border-color: #0d84d9 !important;
}
.dat_ret_switch input:checked + .slider {
  background-color: #20b8ff;
}

input:checked + .slider {
  background-color: #20b8ff;
}

.dat_ret_switch input:checked + .slider:before {
  transform: translateX(25px);
}
input:checked + .slider:before {
  transform: translateX(17px);
}
input:checked + .slider .dat_ret_on {
  display: block;
}
input:checked + .slider .dat_ret_off {
  display: none;
}
.scripts .newscript-offcanvas {
  top: 0 !important;
}
@media (max-width: 575px) {
  .content_flex .lyt_box {
    margin-left: 80px;
  }
}
