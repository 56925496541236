.close {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 24px;
    margin: 0;
    opacity: 0.75;
    width: 24px;
    background: 0 0;
    border: 0;
    font-size: 2.0rem;
}

.btn-canel {
    align-items: center;
    border-radius: 4px !important;
    display: inline-flex;
    font-size: 14px !important;
    gap: 4px;
    justify-content: center;
    line-height: 20px !important;
    padding: 4px 12px !important;
    /* background-color: #0d84d9 !important;
    color: #fff; */
}
.btn-cancel-outline-primary {
    color: #0D84D9 !important;
    border: 1px solid #0D84D9 !important;
    background-color: transparent !important;
    border-radius: 4px !important;
}
.changePassword-modal-footer {
    justify-content: flex-start;
    display: flex;
}
