@font-face {
  font-family: 'vox';
  src: url('../fonts/vox.eot?83769267');
  src: url('../fonts/vox.eot?83769267#iefix') format('embedded-opentype'),
       url('../fonts/vox.woff2?83769267') format('woff2'),
       url('../fonts/vox.woff?83769267') format('woff'),
       url('../fonts/vox.ttf?83769267') format('truetype'),
       url('../fonts/vox.svg?83769267#vox') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'vox';
    src: url('../font/vox.svg?83769267#vox') format('svg');
  }
}
*/
[class^="icon-"] {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "vox";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-circle-up:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-left:before { content: '\e802'; } /* '' */
.icon-arrow-right:before { content: '\e803'; } /* '' */
.icon-arrow-up:before { content: '\e804'; } /* '' */
.icon-attach-file:before { content: '\e805'; } /* '' */
.icon-audio:before { content: '\e806'; } /* '' */
.icon-audio-file:before { content: '\e807'; } /* '' */
.icon-backspace:before { content: '\e808'; } /* '' */
.icon-bell:before { content: '\e809'; } /* '' */
.icon-block:before { content: '\e80a'; } /* '' */
.icon-book:before { content: '\e80b'; } /* '' */
.icon-bookmarks:before { content: '\e80c'; } /* '' */
.icon-building:before { content: '\e80d'; } /* '' */
.icon-cake:before { content: '\e80e'; } /* '' */
.icon-calculator:before { content: '\e80f'; } /* '' */
.icon-calendar-check:before { content: '\e810'; } /* '' */
.icon-calendar-event:before { content: '\e811'; } /* '' */
.icon-calendar-range:before { content: '\e812'; } /* '' */
.icon-calendar-today:before { content: '\e813'; } /* '' */
.icon-call-flow:before { content: '\e814'; } /* '' */
.icon-camera:before { content: '\e815'; } /* '' */
.icon-camera-add:before { content: '\e816'; } /* '' */
.icon-caret-down:before { content: '\e817'; } /* '' */
.icon-caret-left:before { content: '\e818'; } /* '' */
.icon-caret-right:before { content: '\e819'; } /* '' */
.icon-caret-up:before { content: '\e81a'; } /* '' */
.icon-caret-up-down:before { content: '\e81b'; } /* '' */
.icon-certified:before { content: '\e81c'; } /* '' */
.icon-chart-bar:before { content: '\e81d'; } /* '' */
.icon-chart-donut:before { content: '\e81e'; } /* '' */
.icon-chart-pie:before { content: '\e81f'; } /* '' */
.icon-check:before { content: '\e820'; } /* '' */
.icon-checkbox-checked:before { content: '\e821'; } /* '' */
.icon-checkbox-unchecked:before { content: '\e822'; } /* '' */
.icon-check-circle:before { content: '\e823'; } /* '' */
.icon-chevron-left:before { content: '\e824'; } /* '' */
.icon-chevron-right:before { content: '\e825'; } /* '' */
.icon-clipboard:before { content: '\e826'; } /* '' */
.icon-clock:before { content: '\e827'; } /* '' */
.icon-coffee:before { content: '\e828'; } /* '' */
.icon-comment:before { content: '\e829'; } /* '' */
.icon-contacts:before { content: '\e82a'; } /* '' */
.icon-control-panel:before { content: '\e82b'; } /* '' */
.icon-credit-card:before { content: '\e82c'; } /* '' */
.icon-dashboard:before { content: '\e82d'; } /* '' */
.icon-data-usage:before { content: '\e82e'; } /* '' */
.icon-delete:before { content: '\e82f'; } /* '' */
.icon-dialpad:before { content: '\e830'; } /* '' */
.icon-dollar:before { content: '\e831'; } /* '' */
.icon-download:before { content: '\e832'; } /* '' */
.icon-drag:before { content: '\e833'; } /* '' */
.icon-duplicate:before { content: '\e834'; } /* '' */
.icon-edit:before { content: '\e835'; } /* '' */
.icon-ellipsis-h:before { content: '\e836'; } /* '' */
.icon-ellipsis-v:before { content: '\e837'; } /* '' */
.icon-exchange:before { content: '\e838'; } /* '' */
.icon-expand:before { content: '\e839'; } /* '' */
.icon-export:before { content: '\e83a'; } /* '' */
.icon-feedback:before { content: '\e83b'; } /* '' */
.icon-file:before { content: '\e83c'; } /* '' */
.icon-file-copy:before { content: '\e83d'; } /* '' */
.icon-file-template:before { content: '\e83e'; } /* '' */
.icon-folder:before { content: '\e83f'; } /* '' */
.icon-funnel:before { content: '\e840'; } /* '' */
.icon-globe:before { content: '\e841'; } /* '' */
.icon-hamburger:before { content: '\e842'; } /* '' */
.icon-hand:before { content: '\e843'; } /* '' */
.icon-hand-stop:before { content: '\e844'; } /* '' */
.icon-headphone:before { content: '\e845'; } /* '' */
.icon-help:before { content: '\e846'; } /* '' */
.icon-history:before { content: '\e847'; } /* '' */
.icon-history-1:before { content: '\e848'; } /* '' */
.icon-import:before { content: '\e849'; } /* '' */
.icon-info:before { content: '\e84a'; } /* '' */
.icon-info-octagon:before { content: '\e84b'; } /* '' */
.icon-insights:before { content: '\e84c'; } /* '' */
.icon-key-vpn:before { content: '\e84d'; } /* '' */
.icon-list:before { content: '\e84e'; } /* '' */
.icon-location:before { content: '\e84f'; } /* '' */
.icon-lock:before { content: '\e850'; } /* '' */
.icon-lock-open:before { content: '\e851'; } /* '' */
.icon-mail:before { content: '\e852'; } /* '' */
.icon-mail-setting:before { content: '\e853'; } /* '' */
.icon-maximize:before { content: '\e854'; } /* '' */
.icon-megaphone:before { content: '\e855'; } /* '' */
.icon-membership:before { content: '\e856'; } /* '' */
.icon-merge:before { content: '\e857'; } /* '' */
.icon-mic:before { content: '\e858'; } /* '' */
.icon-mic-off:before { content: '\e859'; } /* '' */
.icon-minus:before { content: '\e85a'; } /* '' */
.icon-network:before { content: '\e85b'; } /* '' */
.icon-note:before { content: '\e85c'; } /* '' */
.icon-note-sticky:before { content: '\e85d'; } /* '' */
.icon-pause:before { content: '\e85e'; } /* '' */
.icon-percent:before { content: '\e85f'; } /* '' */
.icon-phone:before { content: '\e860'; } /* '' */
.icon-phone-disabled:before { content: '\e861'; } /* '' */
.icon-phone-enabled:before { content: '\e862'; } /* '' */
.icon-phone-end:before { content: '\e863'; } /* '' */
.icon-phone-flow:before { content: '\e864'; } /* '' */
.icon-phone-forwarding:before { content: '\e865'; } /* '' */
.icon-phone-in-action:before { content: '\e866'; } /* '' */
.icon-phone-incoming:before { content: '\e867'; } /* '' */
.icon-phone-locked:before { content: '\e868'; } /* '' */
.icon-phone-management:before { content: '\e869'; } /* '' */
.icon-phone-merge:before { content: '\e86a'; } /* '' */
.icon-phone-message:before { content: '\e86b'; } /* '' */
.icon-phone-missed-incoming:before { content: '\e86c'; } /* '' */
.icon-phone-missed-outgoing:before { content: '\e86d'; } /* '' */
.icon-phone-on-hold:before { content: '\e86e'; } /* '' */
.icon-phone-outgoing:before { content: '\e86f'; } /* '' */
.icon-phone-plus:before { content: '\e870'; } /* '' */
.icon-phone-setting:before { content: '\e871'; } /* '' */
.icon-phone-volume:before { content: '\e872'; } /* '' */
.icon-play:before { content: '\e873'; } /* '' */
.icon-plus:before { content: '\e874'; } /* '' */
.icon-question-answer:before { content: '\e875'; } /* '' */
.icon-radio-checked:before { content: '\e876'; } /* '' */
.icon-radio-unchecked:before { content: '\e877'; } /* '' */
.icon-real-time:before { content: '\e878'; } /* '' */
.icon-redo:before { content: '\e879'; } /* '' */
.icon-refresh:before { content: '\e87a'; } /* '' */
.icon-renew:before { content: '\e87b'; } /* '' */
.icon-script:before { content: '\e87c'; } /* '' */
.icon-search:before { content: '\e87d'; } /* '' */
.icon-setting:before { content: '\e87e'; } /* '' */
.icon-sign-in:before { content: '\e87f'; } /* '' */
.icon-sign-out:before { content: '\e880'; } /* '' */
.icon-sliders:before { content: '\e881'; } /* '' */
.icon-star:before { content: '\e882'; } /* '' */
.icon-star-full:before { content: '\e883'; } /* '' */
.icon-star-half:before { content: '\e884'; } /* '' */
.icon-stop:before { content: '\e885'; } /* '' */
.icon-subdirectory-arrow-left:before { content: '\e886'; } /* '' */
.icon-subdirectory-arrow-right:before { content: '\e887'; } /* '' */
.icon-tag:before { content: '\e888'; } /* '' */
.icon-task-add:before { content: '\e889'; } /* '' */
.icon-team:before { content: '\e88a'; } /* '' */
.icon-ticket:before { content: '\e88b'; } /* '' */
.icon-timer:before { content: '\e88c'; } /* '' */
.icon-timer-off:before { content: '\e88d'; } /* '' */
.icon-times:before { content: '\e88e'; } /* '' */
.icon-times-circle:before { content: '\e88f'; } /* '' */
.icon-trophy:before { content: '\e890'; } /* '' */
.icon-undo:before { content: '\e891'; } /* '' */
.icon-upload:before { content: '\e892'; } /* '' */
.icon-user:before { content: '\e893'; } /* '' */
.icon-user-group:before { content: '\e894'; } /* '' */
.icon-user-plus:before { content: '\e895'; } /* '' */
.icon-user-setting:before { content: '\e896'; } /* '' */
.icon-vault:before { content: '\e897'; } /* '' */
.icon-verified:before { content: '\e898'; } /* '' */
.icon-visibility:before { content: '\e899'; } /* '' */
.icon-visibility-off:before { content: '\e89a'; } /* '' */
.icon-voicemail:before { content: '\e89b'; } /* '' */
.icon-volume:before { content: '\e89c'; } /* '' */
.icon-volume-off:before { content: '\e89d'; } /* '' */
.icon-wallet:before { content: '\e89e'; } /* '' */
.icon-angle-down:before { content: '\e89f'; } /* '' */
.icon-angle-left:before { content: '\e8a0'; } /* '' */
.icon-angle-right:before { content: '\e8a1'; } /* '' */
.icon-angle-up:before { content: '\e8a2'; } /* '' */
.icon-arrow-circle-down:before { content: '\e8a3'; } /* '' */
