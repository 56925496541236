.page_header {
    align-items: center;
    display: flex;
    height: 32px;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 6px;
  }
.page_header h1 {
    color: #1B1B1B;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
}
.page_header .badge {
    margin-left: 8px;
}
.page_header .btn_back {
    margin-right: 12px;
}
.page_header__content {
    align-items: center;
    display: flex;
}
.breadcrumb {
    background-color: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
}
.breadcrumb li a {
    color: #535F70;
}
.breadcrumb-item {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
}
.breadcrumb > li {
    color: #535F70;
  }
  
.breadcrumb > li + li:before {
    color: #1B1B1B;
}

.breadcrumb > .active {
    color: #1B1B1B !important;
}
.btn_back, .bck_btn {
    align-items: center;
    background: transparent;
    border-radius: 24px;
    border: 1px solid #535F70;
    color: #535F70;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 4px;
    padding: 5px 12px;
    transition: all 0.15s;
}
.btn_back:hover, .bck_btn:hover {
    background: #E8F1FF;
    border-color: #3B4857;
    color: #3B4857;
}

  /* ================ Default page header */
  .title_header {
    color: #1B1B1B;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
  }
  
  .title-lg {
    margin-bottom: 1.2rem;
  }
  
  .nav-link {
    color: #535F70;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    transition: all 0.36s;
  }
  .nav-link:hover {
    color: #0D84D9;
  }
  
  .nav-border-bottom {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .nav-border-bottom .nav-link {
    padding: 1.5rem 0.5rem;
  }
  .nav-border-bottom .nav-link:hover {
    background: transparent;
  }
  .nav-border-bottom .nav-item {
    margin-right: 1.25rem;
  }
  .nav-border-bottom .nav-item.active > a, .nav-border-bottom .nav-item.active > a:focus, .nav-border-bottom .nav-item.active > a:hover {
    color: #0D84D9 !important;
    background: none !important;
  }
  .nav-border-bottom .nav-item.active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #0D84D9;
  }

  .tab01_pane {
    height: 100%;
  }
  
  .tab-content-campaign-detail .active {
    color: #1B1B1B;
  }
  .tab-pane-campaign-detail {
    height: 100%;
  }
 .table_support span:first-child {
    font-size: 24px;
    font-weight: 600;
  }

  .table_support span {
    display: block;
  }