.main-content {
  position: relative;
  margin-left: 70px;
  top: 90px;
  overflow: hidden;
  z-index: 0;
  padding: 8px 20px 12px;
  transition: all 0.36s ease-in-out;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* full screen height */
}
.insights_bar {
  align-items: center;
  background: #fcfcfc;
  border-bottom: 1px solid #e2e2e6;
  display: flex;
  flex: 0 0 44px;
  justify-content: center;
  max-height: 54px;
  padding: 4px 20px;
  overflow: auto;
  position: absolute;
  top: 65px;
  margin-left: 90px;
  width: 100%;
}
.insights_media i {
  font-size: 18px;
}
.insights_item {
  align-items: center;
  display: flex;
  gap: 4px;
}
.insights_item:after {
  color: #535f70;
  content: "|";
  display: inline-block;
  padding: 0 12px;
}
.insights_excerpt p {
  font-size: 11px;
  line-height: 1.35;
  margin: 0;
  padding: 0;
}
.outroute {
  background: none;
  text-align: center;
  position: unset !important;
  z-index: 1;
}
.gd_routes {
  flex: 0 0 4px;
  max-height: 4px;
}
.outroute .outroute_contents {
  color: #464646;
  background: #f1f0f4;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  width: fit-content;
  width: -moz-fit-content;
  padding: 0 8px;
  margin: auto;
}
.outroute span {
  display: inline;
  font-size: 11px;
}
.gd_content {
  flex: 1 1;
  /* overflow: hidden; */
  padding: 8px 20px 12px;
}
.page_header {
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.page_header h1 {
  color: #1b1b1b;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}
.filter_item {
  align-items: center;
  border: 1px solid #73777f;
  border-radius: 4px;
  display: inline-flex;
  grid-gap: 4px;
  min-width: 112px;
  padding: 0 10px;
  font-size: 14px;
}
.filter_select label,
.filter_floating_label label {
  background: #fcfcfc;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  left: 10px;
  letter-spacing: 0.75px;
  padding: 2px 4px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transform: translate(0, -50%);
}
.filter_select,
.filter_floating_label {
  padding: 0;
  position: relative;
}
.filter_select label,
.filter_floating_label label {
  background: #fcfcfc;
  display: inline-block;
  font-size: 10px;
  font-weight: 600;
  left: 14px;
  letter-spacing: 0.75px;
  padding: 2px 4px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  transform: translate(0, -50%);
}
.page_control {
  align-items: center;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.datepicker-box {
  align-items: center;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}
.page_body {
  height: calc(100% - 32px - 12px);
  position: relative;
}
.wid {
  background: #fcfcfc;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 8px 16px;
}
.wid_header {
  align-items: center;
  display: flex;
  flex: 0 0 24px;
  justify-content: space-between;
}
.wid_header h3,
.wid_header h4,
.wid_header h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.wid_body {
  flex: 0 0 calc(100% - 24px - 12px);
  max-height: calc(100% - 24px - 12px);
}
.data_box {
  display: flex;
  grid-gap: 14px;
  height: 100%;
}
.col-lg-4:nth-child(4) .data_box {
  grid-gap: 60px;
}
.main-content_minimized .data_box {
  grid-gap: 12px;
}
.data_box .data_content {
  flex: 0 0 calc(100% - 160px - 12px);
  max-width: calc(100% - 160px - 12px);
}
.data_box .data_index {
  flex: 0 0 160px;
  max-width: 160px;
}
.scroll_y {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
}
.group_title {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin-bottom: 12px;
}
.group_title h3,
.group_title h4,
.group_title h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.index_item {
  align-items: center;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 16px auto 40px;
  font-size: 14px;
}
.filter_item.filter_floating_label.filter_date_time_picker {
  position: relative;
}
.main-content_minimized {
  margin-left: 80px;
}
.tab {
  align-items: center;
  background: #e2e2e6;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  display: inline-flex;
  gap: 2px;
  padding: 2px;
}
.tab_button,
.tab li a {
  background: transparent;
  border: 0 !important;
  border-radius: 2px;
  color: #1b1b1b;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 3px 12px;
  transition: all 0.16s;
}
.switch_act {
  background: #fcfcfc !important;
  color: #1b1b1b !important;
}
.filter_select select,
.filter_select input,
.filter_floating_label select,
.filter_floating_label input {
  padding: 5px 12px;
  z-index: 1;
}
.filter_item input,
.filter_item select {
  background: transparent;
  border: 0;
  border-radius: 0;
  font-weight: 600;
  height: 30px;
  line-height: 20px;
  width: 184px;
  font-size: 14px;
}
.table > tbody > tr > td {
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  vertical-align: middle;
}
.clock-icon {
  color: #9f89ad;
}
.donut-icon {
  color: #009fb6;
}
.phone-icon {
  color: #846f92;
}
.outgoing-icon {
  color: #26a644;
}
.incoming-icon {
  color: #0d84d9;
}
.volume-icon {
  color: #6b5778;
}
.coffee-icon {
  color: #c28400;
}
.circle-icon {
  color: #008a2c;
}
.network-icon {
  color: #007acd;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 10px;
  }
}
@media (max-width: 575px) {
  .page_header {
    flex-direction: column;
    align-items: start;
    height: auto;
    gap: 10px;
  }

  .main-content_minimized {
    margin-left: 10px;
  }
}

/* Add this to your custom CSS file or within a <style> tag in your component */
.date-picker-wrapper {
  position: relative;
  z-index: 1000;
}

.date-picker-wrapper .react-datepicker-wrapper {
  width: auto;
}

.date-picker-wrapper .react-datepicker__tab-loop {
  z-index: 1000;
}

/* .page_control {
    flex-direction: column;
    align-items: start;
  } */
