ul {
  padding: 0 !important;
}
.sidebar {
  max-width: 70px;
  min-width: 70px;
  position: fixed;
  top: 65px;
  height: 100%;
  transition: all 0.36s ease-in-out;
  z-index: 9;
}
.sidebar:hover {
  min-width: 240px;
  box-shadow: 1px 1px 5px 0px #ccc;
}
.sidebar::-webkit-scrollbar,
.menu-list::-webkit-scrollbar {
  display: none;
}
.menu-list {
  flex: 1;
  overflow-x: visible;
  overflow-y: auto;
  padding: 8px;
  padding-bottom: 50px;
  height: 100%;
  scrollbar-width: none !important;
  background: #fcfcfc;
  border-right: 1px solid #e2e2e6;
}
.menu-list ul li {
  align-items: center;

  color: #1b1b1b;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 8px;

  position: relative;
  transition: all 0.15s ease-in-out;
}
.menu-list ul li a {
  padding: 12px 16px;
  color: #1b1b1b;
  width: 100%;
  text-decoration: none;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.menu_bar__minimized .menu-list ul li a {
  padding: 12px 20px;
}
.menu_bar__minimized .menu-list ul li a span {
  margin-right: 0px !important;
}
span.menu-icon {
  font-size: 18px;
  flex: 0 0 32px;
}
.menu_bar__minimized .menu_item__expandable .icon-angle-down {
  display: none;
}
.menu_bar__minimized .menu-list .contact-list .menu_child {
  display: none !important;
}
.menu_child {
  display: none;
  width: 100%;
}
.contact-list a {
  align-items: center;
  color: #1b1b1b;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
}
.menu-list ul li:hover a {
  color: #0d84d9;
  background: #e2e2e6;
}
.menu-list ul li:hover {
}
.menu-list ul li.active {
  background: #cfe4ff;
  color: #0d84d9;
}
.expanded .menu_child {
  display: block !important;
}
.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}
li.contact-list.menu_item__expandable.expanded,
li.contact-list.menu_item__expandable1.expanded {
  flex-direction: column;
}
.contact-list .icon-angle-down {
  position: absolute;
  right: 8px;
  top: 17px;
}
.menu_bar__minimized {
  min-width: 80px;
  max-width: 80px;
}
.menu_bar__minimized .menu-label {
  flex: 0;
  overflow: hidden;
}
.active {
  background-color: #cfe4ff;
  /* color: #0d84d9; */
}
/* responsive start here */
@media (max-width: 768px) {
  .sidebar {
    z-index: 99;
  }
  .menu_bar__minimized {
    min-width: 0;
    max-width: 0;
  }
}

.user-account-wrapper {
  margin-right: 20px;
  position: relative;
}

.dropdown-menu {
  margin-right: 40px;
  display: none; /* Default state is hidden */
}

.dropdown-menu.show {
  display: block;
  position: absolute;
  top: 100%; /* Position below the user account */
  right: 0; /* Align to the right of the user account */
}
