.campaigns-tabs .nav-tabs {
  align-items: center;
  background: #e2e2e6;
  border: 1px solid #e2e2e6;
  border-radius: 4px;
  display: inline-flex;
  gap: 2px;
  padding: 2px;
}
.time_input_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
#pills-Schedule .form-duration {
  gap: 15px;
  display: flex;
}
.time_input_wrapper .form-control {
  flex: 1; /* Make input take all horizontal space except for icon */
}

.time_input_wrapper .icon-clock {
  position: absolute;
  right: 10px; /* Adjust based on your layout */
  pointer-events: none; /* Clicks on the icon won't block input */
}

.nav-pills .nav-link {
  border-bottom: 3px solid transparent;
}

.nav-pills .nav-link.active {
  border-bottom: 3px solid #007acd;
}

/* Add this CSS class to your existing CSS file */

.nav-pills .nav-link.active {
  border-bottom: 2px solid #007acd;
}

.campaigns-tabs .nav-tabs button,
.campaigns-tabs .nav-tabs li a {
  background: transparent;
  border: 0 !important;
  border-radius: 2px;
  color: #1b1b1b;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  padding: 3px 12px;
  transition: all 0.16s;
}
.campaigns-tabs .nav-tabs .nav-item.active a,
.campaigns-tabs .nav-tabs a.active {
  background: #ffffff;
}
.campaigns-tabs .nav-tabs > li.active > a,
.campaigns-tabs .nav-tabs > li.active > a:focus,
.campaigns-tabs .nav-tabs > li.active > a:hover {
  font-size: 13px;
  color: #555 !important;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.ck-editor__editable_inline {
  min-height: 20vh;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.badge.badge-bg-info {
  color: #004e5b;
  background: #a1eeff;
  border: 1px solid #a1eeff;
}

.badge.badge-bg-warning {
  color: #a16d00;
  background: #ffddaa;
  border: 1px solid #ffddaa;
  border-radius: 4px;
}

.badge.badge-bg-primary {
  color: #0d84d9;
  background: #cfe4ff;
  border: 1px solid #cfe4ff;
  border-radius: 4px;
}