.block-piece {
  color: #0d84d9;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e2e2e6;
  transition: 0.3s;
  display: grid;
  flex: 0 0 120px;
  height: 100px;
  place-items: center;
}
.block-piece h6 {
  font-size: 11px !important;
  margin: 0;
}
