.calculatorbody {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}
.calculatorbody .numblock {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 4px;
}
.calculatorbody .numblock span {
  display: grid;
  place-items: center;
  height: 48px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  background: #3F475B;
  border-radius: 9px;
  border: 3px solid #3F475B;
}
.calculatorbody .numblock span:hover {
  background-color: #828A9B;
}

.calc_history {
  background: #E2E2E6;
  border: 1px solid #E2E2E6;
  border-radius: 4px;
  height: 116px;
  margin-bottom: 12px;
  padding: 12px;
}
.calc_history h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 4px;
}
.calc_history p {
  margin: 0;
  word-break: break-all;
}

.calc_display {
  padding: 4px;
  width: 70%;
}
.calc_display input {
  background: #828A9B;
  color: white;
  cursor: pointer;
  height: 48px;
  border: 2px solid #3F475B;
  border-radius: 20px;
  padding-top: 3px;
  margin: 0;
  width: 100%;
}

.calc_ac, .calc_equals {
  padding: 4px;
  width: 15%;
}
.calc_ac span, .calc_equals span {
  background: #9dacad;
  border: 2px solid #3F475B;
  border-radius: 4px;
  display: flex !important;
  font-size: 16px;
  justify-content: center !important;
  align-items: center !important;
  height: 48px;
  width: 100%;
}

.calc_equals span {
  background: #17A2B8;
}

.calc_equals span:hover {
  background: #A1EEFF;
}
  