.view_max__width {
  max-width: 640px;
}
.form_adaptive {
  border: 0 !important;
  border-bottom: 1px solid transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.form_adaptive:focus {
  border-color: #0d84d9 !important;
  box-shadow: none !important;
  outline: none !important;
}
.form_displayPicture .media_group {
  align-items: center;
  display: flex;
  gap: 16px;
  align-items: center;
}
.form_account input[type="file"] {
  border: 0;
  box-shadow: none;
  height: auto;
  padding: 0;
}
.form_account h5 {
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 0;
}
.account-profile {
  height: 38px;
  width: 38px;
  border-radius: 50%;
  object-position: center;
  object-fit: cover;
}
